import { PendingInformationUpdates } from '@core/entities/login/pending-information-updates.entity';
import { noop } from 'rxjs';
import { alias, custom, deserialize, serializable } from 'serializr';

export class SsoDataResponse {
  @serializable(alias('jwt'))
  private _jwt: string;

  @serializable(alias('redirect'))
  private _redirect: boolean;

  @serializable(alias('redirectTo'))
  private _redirectTo: string;

  @serializable(alias('status'))
  private _status: number;

  /**
   * Depending on type of error, error has different payload
   * In the case of a 428 response, error has a userInfo object, so we deserialize and set it
   * In all other cases, we just use the error raw
   */
  @serializable(
    alias(
      'error',
      custom(noop, (error, context) => {
        if (error.userInfo) {
          context.target.userInfo = deserialize(PendingInformationUpdates, error.userInfo);
        }

        return error;
      }),
    ),
  )
  private _error: any;

  /**
   * Is set by error's custom deserialize method when needed
   */
  private _userInfo: PendingInformationUpdates;

  get jwt(): string {
    return this._jwt;
  }

  get redirect(): boolean {
    return this._redirect;
  }

  get redirectTo(): string {
    return this._redirectTo;
  }

  get status(): number {
    return this._status;
  }

  /**
   * @param status
   */
  set status(status: number) {
    this._status = status;
  }

  get error(): any {
    return this._error;
  }

  get userInfo(): PendingInformationUpdates {
    return this._userInfo;
  }

  /**
   * @param userInfo
   */
  set userInfo(userInfo: PendingInformationUpdates) {
    this._userInfo = userInfo;
  }
}
