import { PendingInformationUpdates } from '@core/entities/login/pending-information-updates.entity';
import { User } from '@core/entities/user/user.entity';
import { alias, object, serializable } from 'serializr';

export class OidcLoginEntity {
  @serializable(alias('currentState'))
  private _currentState: string;

  @serializable(alias('user', object(User)))
  private _user: User;

  @serializable(alias('userInfo', object(PendingInformationUpdates)))
  private _userInfo: PendingInformationUpdates;

  get currentState(): string {
    return this._currentState;
  }

  set currentState(value: string) {
    this._currentState = value;
  }

  get user(): User {
    return this._user;
  }

  set user(value: User) {
    this._user = value;
  }

  get userInfo(): PendingInformationUpdates {
    return this._userInfo;
  }

  set userInfo(value: PendingInformationUpdates) {
    this._userInfo = value;
  }
}
