import { Injectable } from '@angular/core';
import { WindowRefHelper } from '@core/helpers/window-ref.helper';

@Injectable({
  providedIn: 'root',
})
export class NavigationHelper {
  /**
   * TODO: decodeURIComponent does not belong here.
   *  - Refactor decode to separate method
   *  - Update used instances before removing from here
   */
  static redirect(url: string): void {
    WindowRefHelper.getNativeWindow().location.href = decodeURIComponent(url);
  }

  static addParamToUrl(url: string, key: string, value: string): string {
    url += url.includes('?') ? '&' : '?';
    url += key + '=' + value;

    return url;
  }

  static uriHasScheme(uri: string, schemes: Array<string>): boolean {
    let url;

    try {
      url = new URL(uri);
    } catch (error) {
      return false;
    }

    return schemes.includes(url.protocol);
  }

  static hasHypertextTransferProtocolScheme(uri: string): boolean {
    return NavigationHelper.uriHasScheme(uri, ['http:', 'https:']);
  }
}
