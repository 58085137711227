import { PendingInformationUpdates } from '@core/entities/login/pending-information-updates.entity';
import { User } from '@core/entities/user/user.entity';
import { alias, object, serializable } from 'serializr';

export class LoginResponse {
  @serializable(alias('userInfo', object(PendingInformationUpdates)))
  private _pendingInformationUpdates: PendingInformationUpdates;

  @serializable(alias('user', object(User)))
  private _user: User;

  get pendingInformationUpdates(): PendingInformationUpdates {
    return this._pendingInformationUpdates;
  }

  get user(): User {
    return this._user;
  }

  /**
   * @param user
   */
  set user(user: User) {
    this._user = user;
  }
}
