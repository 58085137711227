import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { LoginCredentials } from "@core/entities/login/login-credentials.entity";
import { LoginProviderEntity } from "@core/entities/login/login-provider.entity";
import { OidcLoginEntity } from "@core/entities/oauth/oidc-login.entity";
import { LoginResponse } from "@core/entities/response/login-response.entity";
import { SsoDataResponse } from "@core/entities/response/sso-data-response.entity";
import { EnvironmentHelper } from "@core/helpers/environment.helper";
import { NavigationHelper } from "@core/helpers/navigation.helper";
import { SessionService } from "@core/services/user/session.service";
import { Observable, of, throwError } from "rxjs";
import { catchError, concatMap, map, take, tap } from "rxjs/operators";
import { deserialize } from "serializr";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  readonly #httpClient = inject(HttpClient);
  readonly #sessionService = inject(SessionService);

  startSsnMobileLoginSession(
    credentials: LoginCredentials,
    clientAuthAttemptUuid?: string
  ): Observable<string> {
    let p = new HttpParams();

    if (clientAuthAttemptUuid) {
      p = p.set("clientAuthAttemptUuid", clientAuthAttemptUuid);
    }

    return this.#httpClient
      .post(
        EnvironmentHelper.fetchAPIBase(
          "v2/auth/providers/ssn-mobile/create-session"
        ),
        {
          ssn: credentials.ssn,
          phoneNumber: credentials.phone.fullPhoneNumber,
        },
        {
          observe: "response",
          params: p,
        }
      )
      .pipe(
        map((response: HttpResponse<{ entryToken: string }>) => {
          if (response.status === 204) {
            return "";
          } else {
            return response.body.entryToken;
          }
        })
      );
  }

  getLoginProviders(
    clientAuthAttemptUuid?: string
  ): Observable<LoginProviderEntity[]> {
    let p = new HttpParams();

    if (clientAuthAttemptUuid) {
      p = p.set("clientAuthAttemptUuid", clientAuthAttemptUuid);
    }

    return this.#httpClient
      .get(EnvironmentHelper.fetchAPIBase("v2/auth/providers"), {
        observe: "response",
        params: p,
      })
      .pipe(
        map((response: HttpResponse<{ providers: Array<object> }>) => {
          if (response.status === 204) {
            return [];
          } else {
            return deserialize(LoginProviderEntity, response.body.providers);
          }
        })
      );
  }

  oidcCreateSession(
    providerUuid: string,
    currentState?: string,
    clientAuthAttemptUuid?: string
  ): Observable<string> {
    let p = new HttpParams();

    if (clientAuthAttemptUuid) {
      p = p.set("clientAuthAttemptUuid", clientAuthAttemptUuid);
    }

    return this.#httpClient
      .post(
        EnvironmentHelper.fetchAPIBase(
          "v2/auth/providers/openid-connect/create-session"
        ),
        {
          openIdConnectLoginProviderUuid: providerUuid,
          currentState,
        },
        {
          params: p,
        }
      )
      .pipe(
        map((response: { authorizeUrl: string }) => {
          return response.authorizeUrl;
        })
      );
  }

  oidcGetCurrentState(state: string): Observable<string> {
    return this.#httpClient
      .get(
        EnvironmentHelper.fetchAPIBase(
          "v2/auth/providers/openid-connect/current-state-uri"
        ),
        {
          observe: "response",
          params: { state },
        }
      )
      .pipe(
        map((response: HttpResponse<{ currentState: string }>) => {
          return response.body.currentState;
        })
      );
  }

  oidcPostError(
    state?: string,
    error?: string,
    errorDescription?: string,
    extraInfo?: string
  ): Observable<string> {
    return this.#httpClient
      .post(
        EnvironmentHelper.fetchAPIBase(
          "v2/auth/providers/openid-connect/error"
        ),
        {
          state,
          error,
          errorDescription,
          extraInfo,
        }
      )
      .pipe(
        map((response: { currentState: string }) => {
          return response.currentState;
        })
      );
  }

  oidcPostLogin(state: string, code: string): Observable<OidcLoginEntity> {
    return this.#httpClient
      .post(
        EnvironmentHelper.fetchAPIBase(
          "v2/auth/providers/openid-connect/login"
        ),
        {
          state,
          code,
        }
      )
      .pipe(
        map(
          (response: {
            currentState: string;
            user: object;
            userInfo: object;
          }) => {
            return deserialize(OidcLoginEntity, response);
          }
        )
      );
  }

  passwordResetTokenValidation(
    forgottenPasswordCode: string,
    forgottenPasswordUuid: string
  ): Observable<boolean> {
    return this.#httpClient
      .get(
        EnvironmentHelper.fetchAPIBase(
          "v1/forgotten-passwords/" +
            forgottenPasswordUuid +
            "/validate/" +
            forgottenPasswordCode
        )
      )
      .pipe(
        map(() => {
          return true;
        })
      );
  }

  setPassword(
    password: string,
    confirmPassword: string,
    forgottenPasswordUuid: string,
    forgottenPasswordCode: string
  ): Observable<boolean> {
    return this.#httpClient
      .post(
        EnvironmentHelper.fetchAPIBase(
          "v1/forgotten-passwords/" +
            forgottenPasswordUuid +
            "/reset/" +
            forgottenPasswordCode
        ),
        {
          password,
          repeat: confirmPassword,
        }
      )
      .pipe(
        map(() => {
          return true;
        })
      );
  }

  forgotPassword(ssn: string, email: string, url: string): Observable<boolean> {
    return this.#httpClient
      .post(EnvironmentHelper.fetchAPIBase("v1/forgotten-passwords"), {
        ssn,
        email,
        url,
      })
      .pipe(
        map(() => {
          return true;
        })
      );
  }

  login(
    otp: string,
    entryToken: string,
    password?: string
  ): Observable<LoginResponse> {
    return this.#httpClient
      .post(
        EnvironmentHelper.fetchAPIBase("v2/auth/providers/ssn-mobile/login"),
        {
          otp,
          password,
          entryToken,
        }
      )
      .pipe(
        map(
          (response: {
            user: object;
            pendingInformationUpdates: object;
            token: string;
          }) => {
            return deserialize(LoginResponse, response);
          }
        )
      );
  }

  logout(): Observable<boolean> {
    const logout$ = this.#httpClient
      .post(EnvironmentHelper.fetchAPIBase("v1/auth/logout"), {})
      .pipe(
        tap(() => {
          this.#sessionService.clearUser();
        }),
        map(() => {
          return true;
        }),
        catchError((error: any) => {
          this.#sessionService.clearUser();
          return throwError(() => error);
        })
      );

    return this.#sessionService.isImpersonating().pipe(
      concatMap((isImpersonating: boolean) => {
        if (!isImpersonating) {
          return logout$;
        }

        return this.#sessionService.stopImpersonatingUser().pipe(
          concatMap(() => {
            return logout$;
          })
        );
      }),
      take(1),
      catchError((error: any) => {
        // Logout should not return 401, but need to handle for now
        if (error.status === 401) {
          return throwError(() => error);
        }

        NavigationHelper.redirect(EnvironmentHelper.getLogoutUrl());
        return throwError(() => error);
      })
    );
  }

  ssoAuthenticate(
    appId: string,
    returnTo: string
  ): Observable<SsoDataResponse> {
    return this.#httpClient
      .post(
        EnvironmentHelper.fetchAPIBase("v1/auth/sso"),
        {
          appId,
          returnTo,
        },
        {
          observe: "response",
        }
      )
      .pipe(
        map(
          (
            response: HttpResponse<{
              pendingInformationUpdates: Array<object>;
              jwt: string;
              redirect: boolean;
              redirectTo: string;
            }>
          ) => {
            const ssoDataResponse = deserialize(SsoDataResponse, response.body);
            ssoDataResponse.status = response.status;
            return ssoDataResponse;
          }
        ),
        catchError((error: any) => {
          return of(deserialize(SsoDataResponse, error));
        })
      );
  }

  userInfoUpdated(): Observable<boolean> {
    return this.#httpClient
      .post(
        EnvironmentHelper.fetchAPIBase("v1/users/self/information-updated"),
        {}
      )
      .pipe(
        map(() => {
          return true;
        })
      );
  }
}
